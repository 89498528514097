import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import CatgoryCol from "./category-directory-col"
import Search from "./category-directory-search"
import { Spinner } from "reactstrap"

const CategoriesDirectoryMain = () => {
  const [search, setSearch] = useState("")
  const [array, setArray] = useState([])
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { glob: "**/src/markdown-pages/*.md" } }
        sort: { order: ASC, fields: [frontmatter___title] }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              path
              articleHeading
              categoryName
              categoryID
              path
            }
            html
          }
        }
      }
    }
  `)
  useEffect(() => {
    setArray([...data.allMarkdownRemark.edges])
  }, [data.allMarkdownRemark.edges])
  const handleMethode = search => {
    const temp = [...array]
    if (search) {
      let newArray = temp
        ?.filter(
          e =>
            new RegExp(search, "i").test(e.node.frontmatter.categoryName) ||
            new RegExp(search, "i").test(e.node.frontmatter.title)
        )

        .map(e => {
          let newTitle = e.node.frontmatter.title.replace(
            new RegExp(search, "i"),
            e => `<mark>${e}</mark>`
          )
          let newCategoryName = e.node.frontmatter.categoryName.replace(
            new RegExp(search, "i"),
            e => `<mark>${e}</mark>`
          )

          return {
            ...e,
            title: newTitle,
            categoryName: newCategoryName,
          }
        })
      setArray([...newArray])
    } else {
      setArray([...data.allMarkdownRemark.edges])
    }
  }

  useEffect(() => {
    handleMethode(search)
  }, [search])

  const handleSubmit = e => {
    e.preventDefault()
    setArray([...data.allMarkdownRemark.edges])
    handleMethode(search)
  }

  return (
    <div className="container-fluid cat-main-container">
      <Search
        handleSubmit={handleSubmit}
        handleMethode={handleMethode}
        search={search}
        setSearch={setSearch}
      />
      {array.length > 0 ? (
        <CatgoryCol array={array} />
      ) : (
        <div>
          <Spinner color="primary" />
          <Spinner color="secondary" />
          <Spinner color="success" />
          <Spinner color="danger" />
          <Spinner color="warning" />
          <Spinner color="info" />
          <Spinner color="light" />
          <Spinner color="dark" />
        </div>
      )}
    </div>
  )
}

export default CategoriesDirectoryMain
