import React from "react"
import { Layout, SEO } from "../components"
import CategoriesDirectoryMain from "../components/categories/category-dir-main"

const CategoriesDirectory = () => {
  return (
    <Layout>
      <SEO title="All Categories" />
      <CategoriesDirectoryMain />
    </Layout>
  )
}

export default CategoriesDirectory
