import React from "react"

const Search = ({ handleSubmit, handleMethode, search, setSearch }) => {
  return (
    <div className="row dir-main-input-row container">
      <form type="submit" onSubmit={handleSubmit} className="dir-main-input">
        <input
          id="search"
          type="text"
          name="search"
          value={search}
          onChange={e => {
            setTimeout(handleMethode(setSearch(e.target.value), 500))
          }}
        />
      </form>
    </div>
  )
}

export default Search
