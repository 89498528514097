import React from "react"
import { Card } from "reactstrap"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFingerprint,
  faBook,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons"
const CategoryCol = ({ array }) => {
  return (
    <div className="row container cat-dir-row ">
      {array.map(e => (
        <div key={e.node.id}>
          {/* <div className="col-12 all-dir-first-column "> */}
          {/* <p
              className="cat-dir-categoryName-outside"
              id="text"
              dangerouslySetInnerHTML={{
                __html: e?.categoryName || e.node.frontmatter?.categoryName,
              }}
            ></p>
            <p
              className="cat-dir-title-outside"
              style={{ color: "#3d619b" }}
              dangerouslySetInnerHTML={{
                __html: e?.title || e.node.frontmatter?.title,
              }}
            ></p>
            <p className="cat-dir-path-outside">
              <Link to={e.node.frontmatter.path}>
                {e.node.frontmatter.path}
              </Link>
            </p> */}
          {/* </div> */}
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 all-dir-second-column ">
            <div>
              <Card className="cat-dir-card">
                <Link to={e.node.frontmatter.path}>
                  <p
                    className="cat-dir-title-inside"
                    dangerouslySetInnerHTML={{
                      __html: `Need ${e?.title || e.node.frontmatter?.title} ?`,
                    }}
                  ></p>
                </Link>
                <br />
                <hr className="style1" style={{ marginBottom: "2%" }} />
                <p className="cat-dir-path-inside">
                  <Link to={e.node.frontmatter.path}>
                    <FontAwesomeIcon icon={faClipboardList} />{" "}
                    {e.node.frontmatter?.categoryName}
                  </Link>
                  <br />
                  <FontAwesomeIcon icon={faFingerprint} />{" "}
                  {e.node.frontmatter.categoryID}
                  <br />
                  <Link to={e.node.frontmatter.path}>
                    <FontAwesomeIcon icon={faBook} /> {e.node.frontmatter.path}
                  </Link>
                </p>
              </Card>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CategoryCol
